import React, { useRef, useEffect } from "react";
import Typed from "typed.js";
import { colors } from "../style/theme.js";

function TypedString({ strings }) {
  const options = {
    strings: strings,
    typeSpeed: 60,
    backSpeed: 60,
    loop: true
  };
  const span = useRef(null);

  useEffect(() => {
    const typed = new Typed(span.current, options);
  }, []);

  return (
    <span
      ref={span}
      css={{
        color: colors.light,
        fontWeight: 400,
        borderBottom: `2px solid ${colors.primary}`
      }}
    ></span>
  );
}

export default TypedString;
