import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import { colors } from "style/theme";
import LocalizedLink from "components/localizedLink";
import styled from "@emotion/styled";

const CookieBanner = () => {
  const [cookie, setCookie] = useState(false);
  const cookieAccept = () => {
    setCookie(true);
  };
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-KN8W04GHB7");
  }, []);

  const Link = styled.a({
    fontSize: "85%",
    fontWeight: 500,
    color: colors.light,
    transition: "0.2s",
    ":hover": {
      color: colors.primary,
    },
  });

  return (
    <div>
      {cookie === true ? (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-KN8W04GHB7"
          ></script>
        </Helmet>
      ) : (
        ""
      )}
      <CookieConsent
        onAccept={cookieAccept}
        enableDeclineButton
        flipButtons
        location="bottom"
        buttonText="Accept"
        cookieName="cookieBanner"
        style={{ background: "#2B373B" }}
        declineButtonStyle={{
          backgroundColor: colors.light,
          color: colors.dark,
        }}
        buttonStyle={{ backgroundColor: colors.primary, color: colors.dark }}
      >
        We value your privacy. Our website uses cookies and similar technologies
        to ensure its proper operation and to enhance your experience. Some
        cookies are essential for the website to function, while others are used
        for improving user experience, personalizing ads and content, and
        analyzing site usage. Under data protection laws, processing personal
        data, such as IP addresses, may require your consent. If you are under
        16, please obtain consent from your parent or guardian before using
        voluntary services.
        <br />
        <span style={{ fontSize: "12px" }}>
          For detailed information about the cookies we use, the purposes for
          which we use them, please refer to our Data{" "}
          <Link as={LocalizedLink} to="about/privacy">
            <span style={{ fontSize: "12px", color: colors.primary }}>
              Privacy Policy
            </span>
          </Link>
          .
        </span>
      </CookieConsent>
    </div>
  );
};

export default CookieBanner;
