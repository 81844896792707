const whatWeDo = [
  {
    id: 0,
    title: "Archive",
    desc:
      "Mnemonic has established four stand-alone archives preserving vulnerable digital information from <a href='https://syrianarchive.org/en' rel='noopener noreferrer' target='_blank' >Syria</a>, <a href='https://sudanesearchive.org/en' rel='noopener noreferrer' target='_blank'>Sudan</a>, <a href='https://yemeniarchive.org/en' rel='noopener noreferrer' target='_blank' >Yemen</a>, and <a href='https://ukrainianarchive.org/en' rel='noopener noreferrer' target='_blank' >Ukraine</a>.",
    link: "our-work#archive"
  },
  {
    id: 1,
    title: "Rapid Response",
    desc:
      "As situations of political violence emerge, our Rapid Response project helps new documentation initiatives get off the ground fast and securely. Currently, our Rapid Response project is developing archives for numerous locations, for example Iran and Armenia.",
    link: "our-work#rapid"
  },
  {
    id: 2,
    title: "Training",
    desc:
      "We train human rights defenders to securely archive, verify, and investigate - maximising digital documentation’s impact and empowering those working with it. ",
    link: "our-work#training"
  },
  {
    id: 3,
    title: "Content Moderation",
    desc:
      "We engage with platforms to reduce the impact of harmful content moderation policies by social media companies and governments, and empower advocacy efforts by providing comprehensive reliable data surrounding the takedowns of human rights documentation on social media platforms.",
    link: "our-work#content-moderation"
  },
  {
    id: 4,
    title: "Tools and Methods",
    desc:
      "We build and support the development of open source tools and methods empowering those working towards justice and accountability.",
    link: "our-work#tools-and-method"
  }
];

export default whatWeDo;
