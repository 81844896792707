import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { rhythm, scale } from "utils/typography";
import { mq } from "utils/helper";
import LocalizedLink from "components/localizedLink";
import { colors } from "style/theme";
import useTranslations from "components/hooks/useTranslations";
import { LocaleContext } from "context/locale-context";

const CategoryNameRow = styled.div({
  display: "flex",
  margin: "2.5rem 0",
  padding: "0 1.5rem",
  width: "100%",
  textTransform: "uppercase",
  color: colors.light,
  fontSize: "18px",
  letterSpacing: "0.23px",
  fontWeight: "500",
  justifyContent: "space-between",
  [mq[0]]: {
    marginBottom: 0
  }
});

const H4 = styled.h4({
  ...scale(1 / 4),
  color: colors.light,
  letterSpacing: "0.23px",
  fontWeight: "normal",
  margin: 0,
  transition: "0.25s",
  ":hover, :active": {
    textDecoration: "underline"
  },
  [mq[0]]: {
    ...scale(0.4),
    fontWeight: 500,
    marginLeft: rhythm(1),
    marginBottom: rhythm(1)
  }
});

const Small = styled.small({
  fontSize: "85%",
  letterSpacing: "0.88px",
  fontWeight: "bold",
  transition: "0.25s",
  ":hover, :active": {
    textDecoration: "underline"
  },
  [mq[0]]: {
    display: "none"
  }
});

const PostTitle = styled.h3(props => ({
  color: colors.light,
  marginTop: rhythm(1 / 2),
  position: "relative",
  fontWeight: "bold",
  transition: "0.25s",
  "&::before": {
    position: "absolute",
    left: props.isRTL ? "0" : "-1.5rem",
    right: props.isRTL ? "-1.5rem" : "0",
    content: '"•"',
    color: colors.primary
  },
  ":hover, :active": {
    textDecoration: "underline"
  }
}));

const PostDesc = styled.p({
  color: colors.light,
  lineHeight: "1rem",
  [mq[0]]: {
    marginBottom: 0
  }
});

const PostColumns = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: rhythm(3),
  marginRight: rhythm(3),
  paddingBottom: rhythm(3),
  ".update": {
    width: "30%",
    [mq[0]]: {
      width: "100%",
      marginBottom: rhythm(2)
    }
  },
  [mq[0]]: {
    display: "block",
    marginLeft: rhythm(1),
    marginRight: rhythm(1),
    paddingBottom: 0
  }
});

const PostDate = styled.small({
  fontWeight: 500,
  opacity: 0.6,
  color: colors.primary
});

const Box = styled.div({
  maxWidth: "1200px",
  marginLeft: "auto",
  marginRight: "auto",
  border: `1px solid ${colors.primary}`,
  background: "rgba(0, 0, 0, 0)",
  [mq[0]]: {
    border: "none",
    background: "rgba(0,0,0,0)",
    boxShadow: "none",
    marginLeft: rhythm(1),
    marginRight: rhythm(1)
  }
});

function PressCoverage({ nodes }) {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  return (
    <>
      <div
        css={{
          maxWidth: "1200px",
          margin: "auto",
          paddingTop: rhythm(4),
          paddingBottom: rhythm(4),
          direction: isRTL ? "rtl" : "ltr",
          "& .press-link": {
            color: colors.primary
          },
          [mq[1]]: {
            paddingTop: rhythm(1),
            paddingBottom: rhythm(1)
          }
        }}
      >
        <h4
          css={{
            fontSize: "16px",
            padding: `${rhythm(0.3)} ${rhythm(0.9)} ${rhythm(0.1)}`,
            fontWeight: 300,
            backgroundColor: colors.primary,
            display: "inline-block",
            margin: "0 auto",
            textAlign: "center"
          }}
        >
          {tr("Press")}
        </h4>
        <Box>
          <CategoryNameRow>
            <p>Recent press coverage of the work of Mnemonic</p>
            <LocalizedLink
              css={{
                color: colors.primary,
                ":hover": { textDecoration: "underline" },
                [mq[1]]: {
                  display: "none"
                }
              }}
              to="about/press"
            >
              MORE +
            </LocalizedLink>
          </CategoryNameRow>
          <PostColumns>
            {nodes.map(post => (
              <div className="update" key={post.id}>
                <a href={post.link} rel="noopener noreferrer" target="_blank">
                  <PostTitle isRTL={isRTL}>{post.title}</PostTitle>
                </a>
                <PostDate>{post.date}</PostDate>
              </div>
            ))}
          </PostColumns>
        </Box>
      </div>
    </>
  );
}

PressCoverage.propTypes = {
  nodes: PropTypes.array
};

export default PressCoverage;
