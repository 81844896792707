import React from "react";
import { colors, H2 } from "style/theme";
import useTranslations from "components/hooks/useTranslations";
import { rhythm, scale } from "utils/typography";
import whatWeDo from "../../../data/what-we-do";
import LocalizedLink from "components/localizedLink";
import { mq } from "../../utils/helper";

function WhatWeDo() {
  const tr = useTranslations();
  return (
    <>
      <div
        css={{
          maxWidth: "950px",
          margin: "auto"
        }}
        id="what-we-do"
      >
        <h4
          css={{
            fontSize: "16px",
            padding: `${rhythm(0.3)} ${rhythm(0.9)} ${rhythm(0.1)}`,
            fontWeight: 300,
            backgroundColor: colors.primary,
            display: "inline-block",
            margin: 0,
            textAlign: "center",
            [mq[1]]: {
              //              fontSize: "20px",
              //            marginLeft: "2rem"
            }
          }}
        >
          {tr("What we do")}
        </h4>
      </div>
      <div
        css={{
          maxWidth: "950px",
          margin: "auto",
          border: `1px solid ${colors.primary}`,
          padding: `${rhythm(2)} ${rhythm(3)}`,
          [mq[1]]: {
            padding: rhythm(1.5),
            border: "none"
          }
        }}
      >
        {whatWeDo.map(item => (
          <div
            key={item.id}
            css={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: rhythm(4),
              alignItems: "center",
              [mq[1]]: {
                paddingTop: rhythm(2),
                display: "block"
              }
            }}
          >
            <H2 css={{ fontSize: "39.06px" }}>
              <LocalizedLink
                css={{
                  color: colors.primary,
                  ":hover": { color: colors.light, textDecoration: "underline" }
                }}
                to={item.link}
              >
                {item.title}
              </LocalizedLink>
            </H2>
            <div
              css={{
                width: "60%",
                [mq[1]]: {
                  width: "100%"
                }
              }}
            >
              <div
                css={{
                  color: colors.light,
                  ...scale(0.25),
                  lineHeight: "27px",
                  marginBottom: rhythm(0.3),
                  "& a": {
                    color: colors.primary,
                    ":hover": {
                      textDecoration: "underline"
                    }
                  }
                }}
                dangerouslySetInnerHTML={{ __html: item.desc }}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default WhatWeDo;
